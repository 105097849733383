import cn from "classnames";
import { motion, useReducedMotionConfig } from "framer-motion";
import { Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import TrackableLink from "../../atoms/trackable-link/TrackableLink";
import { useGetIsNavigationStuck } from "../../core/application/navigation-use-cases";
import { CountryCode } from "../../settings/countries";
import { Events } from "../../utils/analytics";
import * as styles from "./Navigation.module.scss";

interface Image {
  default: string;
}

const logoSVG: Image = require("../../images/logo/barkibu.svg") as Image;

const logoSVGSrc: string = logoSVG.default;

interface NavigationProps {
  location?: Window["location"];
  countryCodeIso?: CountryCode;
}

const Navigation = ({
  location,
  countryCodeIso = CountryCode.ES,
}: NavigationProps): JSX.Element => {
  const isNavigationStuck = useGetIsNavigationStuck();
  const reduceMotion = useReducedMotionConfig();
  const { t } = useTranslation();
  const logoWrapper = (
    <>
      {location?.pathname === `/${countryCodeIso.toLowerCase()}/` ? (
        <img className={styles.brand} src={logoSVGSrc} alt="Barkibu" />
      ) : (
        <Link to={`/${countryCodeIso.toLowerCase()}/`} className={styles.brand}>
          <img src={logoSVGSrc} alt="Barkibu" width="90px" height="21px" />
        </Link>
      )}
    </>
  );

  return (
    <>
      <header className={cn(styles.navigation, "or-navigation")}>
        <section className={cn(styles.mainNavigation, "main-navigation")}>{logoWrapper}</section>
      </header>

      {isNavigationStuck && (
        <motion.div
          className={cn(styles.navigationStuck)}
          {...(!reduceMotion && {
            initial: { scaleY: 0, opacity: 0 },
            animate: { scaleY: 1, opacity: 1 },
            transition: {
              opacity: { ease: "easeOut", duration: 0.3 },
              scaleY: { ease: "easeInOut", duration: 0.3 },
            },
            exit: { scaleY: 1, opacity: 1 },
          })}
        >
          {logoWrapper}
          <TrackableLink
            to={`/${countryCodeIso.toLowerCase()}/onboarding/species/`}
            eventName={Events.CLICKED_CTA}
            isExternalLink={false}
            adoptionClassName={styles.navigationCta}
            eventSender={"Header Home CTA"}
          >
            {t("landing.get_budget.header_stuck.cta")}
          </TrackableLink>
        </motion.div>
      )}
    </>
  );
};
export default Navigation;
