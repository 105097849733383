import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { isNavigationStuckState } from "../state/navigation-state";

export const useGetIsNavigationStuck = (): boolean => useRecoilValue(isNavigationStuckState);

export function useSetIsNavigationStuck(isStuck: boolean): void {
  const setReducedMotionState = useSetRecoilState(isNavigationStuckState);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setReducedMotionState(isStuck);
    }, 150);

    return () => clearTimeout(timeout);
  }, [isStuck, setReducedMotionState]);
}
