import { fetchApi, fetchApiJson } from "../../../utils/request";
import { ResultDTO } from "../domain/result";

function resultUrl(resultUuid: string): string {
  return `${process.env.GATSBY_API_BASE}/results/${resultUuid}/`;
}

export async function getDataLayerProperties(resultUuid: string): Promise<Record<string, unknown>> {
  return await fetchApiJson(`${resultUrl(resultUuid)}data_layer_properties/`);
}

export async function getIdentifyProperties(resultUuid: string): Promise<any> {
  return await fetchApiJson(`${resultUrl(resultUuid)}identify_properties/`);
}

export async function getResult(resultUuid: string): Promise<ResultDTO> {
  return await fetchApiJson(`${resultUrl(resultUuid)}?include=quotes`);
}

export async function updateResult(resultUuid: string, body: string): Promise<ResultDTO> {
  return await fetchApiJson(`${resultUrl(resultUuid)}?include=quotes`, {
    body,
    method: "PATCH",
  });
}

export async function createResult(body: string): Promise<ResultDTO> {
  return await fetchApiJson(`${process.env.GATSBY_API_BASE}/results/`, {
    body,
    method: "POST",
  });
}

export async function prepareForBind(resultUuid: string): Promise<void> {
  await fetchApi(`${resultUrl(resultUuid)}prepare_for_bind/`, {
    method: "POST",
  });
}

export async function confirmResult(resultUuid: string): Promise<Record<string, string>> {
  return await fetchApiJson(`${resultUrl(resultUuid)}confirm/`, {
    method: "POST",
  });
}

export async function getPaymentUrl(resultUuid: string, body: string): Promise<{ url: string }> {
  return await fetchApiJson<{ url: string }>(`${resultUrl(resultUuid)}checkout_session/`, {
    body,
    method: "POST",
  });
}
