import { atom, selector } from "recoil";

import { rollbar, RollbarLogArgument } from "../../../utils/rollbar";
import { ResultDTO } from "../domain/result";
import {
  getDataLayerProperties,
  getIdentifyProperties,
  getResult,
} from "../repositories/result-repository";

export const currentResultUuidState = atom<string | null>({
  key: "CurrentResultUuidState",
  default: null,
});

export const resultStateSelector = selector<ResultDTO | null>({
  key: "result",
  get: async ({ get }) => {
    const currentResultUuid = get(currentResultUuidState);
    if (!currentResultUuid) {
      return null;
    }

    return await getResult(currentResultUuid);
  },
  set: ({ set }, newData) => {
    set(resultState, newData);
  },
});

export const resultState = atom({
  key: "ResultState",
  default: resultStateSelector,
});

export const dataLayerPropertiesState = selector<any | null>({
  key: "dataLayerProperties",
  get: async ({ get }) => {
    const currentResultUuid = get(currentResultUuidState);
    if (!currentResultUuid) {
      return null;
    }
    try {
      return await getDataLayerProperties(currentResultUuid);
    } catch (err) {
      if (err instanceof Error) {
        rollbar.error(err.message, err as RollbarLogArgument, {
          context: "getDataLayerProperties",
        });
      }

      return null;
    }
  },
});

export const identifyPropertiesState = selector({
  key: "identifyProperties",
  get: async ({ get }) => {
    const currentResultUuid = get(currentResultUuidState);
    if (!currentResultUuid) {
      return null;
    }
    try {
      return await getIdentifyProperties(currentResultUuid);
    } catch (err) {
      if (err instanceof Error) {
        rollbar.error(err.message, err as RollbarLogArgument, { context: "getIdentifyProperties" });
      }

      return null;
    }
  },
});
